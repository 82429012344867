import React from 'react';
import AnimationRevealPage from 'helpers/AnimationRevealPage.js';

import Hero from 'components/hero/BackgroundAsImage.js';
import Features from 'components/features/DashedBorderSixFeatures';
import MainFeature from 'components/features/TwoColSingleFeatureWithStats2.js';
import Gallery from 'components/blogs/GridWithFeaturedPost';
import ContactUsForm from 'components/forms/TwoColContactUsWithIllustrationFullForm.js';
import Footer from 'components/footers/MiniCenteredFooter';

export default () => (
  <AnimationRevealPage>
    <Hero />
    <MainFeature />
    <Features />
    <Gallery />
    <ContactUsForm />
    <Footer />
  </AnimationRevealPage>
);
