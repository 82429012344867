import React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import { SectionHeading } from 'components/misc/Headings.js';

import PalletsIconImage from '../../images/pallets.svg';
import PartsIconImage from '../../images/parts.svg';
import PlanksIconImage from '../../images/planks.svg';
import BoxesIconImage from '../../images/boxes.svg';
import FirewoodIconImage from '../../images/firewood.svg';
import OtherIconImage from '../../images/other.svg';

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-20 md:py-24`}
`;
const Heading = tw(SectionHeading)`w-full mb-10`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 px-6 flex`}
`;

const Card = styled.div`
  ${tw`flex flex-col mx-auto max-w-xs items-center px-6 py-10 border-2 border-dashed border-primary-500 rounded-lg mt-12`}
  .imageContainer {
    ${tw`border-2 border-primary-500 text-center rounded-full p-6 flex-shrink-0 relative`}
    img {
      ${tw`w-8 h-8`}
    }
  }

  .textContainer {
    ${tw`mt-6 text-center`}
  }

  .title {
    ${tw`mt-2 font-bold text-xl leading-none text-primary-500`}
  }

  .description {
    ${tw`mt-3 font-semibold text-secondary-100 text-sm leading-loose`}
  }
`;

export default () => {
  const cards = [
    {
      imageSrc: PalletsIconImage,
      title: 'Pallets',
      description:
        'One way or other types of pallets in various sizes, including used euro and other pallets.',
    },
    {
      imageSrc: PartsIconImage,
      title: 'Pallet Parts',
      description:
        'Wood for pallet production (first and second class) based on your business needs.',
    },
    {
      imageSrc: PlanksIconImage,
      title: 'Planks',
      description:
        'Industrial planks (first and second class) in various dimensions and types of wood.',
    },
    {
      imageSrc: BoxesIconImage,
      title: 'Boxes',
      description:
        'Wooden boxes from very small to extra large sizes based on your company needs.',
    },
    {
      imageSrc: FirewoodIconImage,
      title: 'Firewood',
      description:
        'Firewood from birch, oak, ash, or other types of tree, which can be dried or left raw.',
    },
    {
      imageSrc: OtherIconImage,
      title: 'Other',
      description:
        'We are constantly adding other products to our supply. Contact us for other products and materials.',
    },
  ];

  return (
    <Container>
      <ThreeColumnContainer>
        <Heading id="products">
          Our High Quality <span tw="text-primary-500">Products</span>
        </Heading>
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="imageContainer">
                <img src={card.imageSrc} alt="" />
              </span>
              <span className="textContainer">
                <span className="title">{card.title || 'Fully Secure'}</span>
                <p className="description">
                  {card.description ||
                    'Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud. Sic Semper Tyrannis. Neoas Calie artel.'}
                </p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
    </Container>
  );
};
