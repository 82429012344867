import React from 'react';
import { Container, ContentWithPaddingXl } from 'components/misc/Layouts';
import tw from 'twin.macro';
import styled from 'styled-components';
import { css } from 'styled-components/macro';
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from 'components/misc/Headings';
import { SectionDescription } from 'components/misc/Typography';

const HeadingContainer = tw.div`text-center`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)``;
const Description = tw(SectionDescription)`mx-auto`;

const Posts = tw.div`mt-10 flex flex-wrap -mr-3 relative`;
const Post = tw.a`flex flex-col h-full bg-gray-200 rounded`;
const PostImage = styled.div`
  ${(props) =>
    css`
      background-image: url('${props.imageSrc}');
    `}
  ${tw`h-64 sm:h-80 bg-center bg-cover rounded-t`}
`;

const PostContainer = styled.div`
  ${tw`relative z-20 mt-10 sm:pt-3 pr-8 w-full sm:w-1/2 lg:w-1/3 max-w-sm mx-auto sm:max-w-none sm:mx-0`}

  ${(props) =>
    props.featured &&
    css`
      ${tw`w-full sm:w-full lg:w-2/3`}
      ${Post} {
        ${tw`sm:flex-row items-center sm:pr-3`}
      }
      ${PostImage} {
        ${tw`sm:h-80 sm:min-h-full w-full sm:w-1/2 rounded-t sm:rounded-t-none sm:rounded-l`}
      }
    `}
`;

export default ({
  subheading = '',
  description = '',
  posts = [
    {
      postImageSrc: 'c9.jpg',
    },
    {
      postImageSrc: 'c2.jpg',
    },
    {
      postImageSrc: 'c18.jpg',
    },
    {
      postImageSrc: 'c7.jpg',
    },
    {
      postImageSrc: 'c17.jpg',
    },
    {
      postImageSrc: 'c12.jpg',
    },
  ],
}) => {
  return (
    <Container>
      <ContentWithPaddingXl>
        <HeadingContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          <Heading>
            Some Production <span tw="text-primary-500">Examples</span>
          </Heading>
          {description && <Description>{description}</Description>}
        </HeadingContainer>
        <Posts>
          {posts.map((post, index) => (
            <PostContainer featured={post.featured} key={index}>
              <Post className="group" href={post.postImageSrc} target="_blank">
                <PostImage imageSrc={post.postImageSrc} />
              </Post>
            </PostContainer>
          ))}
        </Posts>
      </ContentWithPaddingXl>
    </Container>
  );
};
