import React from 'react';
import AnimationRevealPage from 'helpers/AnimationRevealPage.js';
import { Container, Content2Xl } from 'components/misc/Layouts';
import tw from 'twin.macro';

import { LogoLink } from 'components/headers/light.js';
import { SectionHeading as HeadingBase } from 'components/misc/Headings';
import { SectionDescription as DescriptionBase } from 'components/misc/Typography';

import logo from 'images/logo.svg';

/* Hero */
const Row = tw.div`flex`;
const NavRow = tw(Row)`flex flex-col lg:flex-row items-center justify-between`;
const HeroRow = tw(
  Row
)`max-w-xl flex-col justify-between items-center py-20 lg:py-24 mx-auto`;

const Heading = tw(HeadingBase)`text-center text-primary-900 leading-snug`;
const Description = tw(
  DescriptionBase
)`mt-4 text-center lg:text-base text-gray-700 max-w-lg mx-auto lg:mx-0`;

export default () => {
  /*
   * Using gtag like this because we only want to use Google Analytics when Main Landing Page is rendered
   * Remove this part and the the gtag script inside public/index.html if you dont need google analytics
   */
  window.gtag('js', new Date());
  window.gtag('config', 'UA-45799926-9');

  const downloadUrl = '/treact-ui.zip';
  React.useEffect(() => {
    var iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    iframe.src = downloadUrl;
    document.body.appendChild(iframe);
  }, []);

  return (
    <AnimationRevealPage disabled>
      <Container tw="-mx-8 -mt-8 pt-8 px-8">
        <Content2Xl>
          <NavRow>
            <LogoLink href="/">
              <img src={logo} alt="" />
              Sevikos Prekyba
            </LogoLink>
            <div tw="flex flex-col lg:flex-row items-center"></div>
          </NavRow>
          <HeroRow>
            <Heading>Thank You!</Heading>
            <Description tw="mt-12">We Will Contact Your Shortly.</Description>
            <div tw="mt-12 text-center">
              You can also contact us by phone or email:
              <br />
              <br />
              For English speakers:
              <br />
              <span tw="font-bold">+37061329554</span> <br />
              <span tw="font-bold">vytautas.koncius@gmail.com</span>
              <br />
              <br />
              For Russian speakers:
              <br />
              <span tw="font-bold">+37069834626</span> <br />
              <span tw="font-bold">info@sevikosprekyba.lt</span>
              <br />
              <br />
              <br />
              <br />
              <a href={"/"}><u>Return to main page</u></a>
            </div>
          </HeroRow>
        </Content2Xl>
      </Container>
    </AnimationRevealPage>
  );
};
